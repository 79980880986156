import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  referrerCode: '',
  cookiePermissions: {
    googleAnalytics: false,
    googleSSO: false,
    upScope: false,
    microsoftClarity: false,
    chatra: false,
    facebookPixel: false,
    // klaviyo: false,
  },
};

const persistorSlice = createSlice({
  initialState,
  name: 'persistor',
  reducers: {
    setReferrerCode(state, action) {
      state.referrerCode = action.payload;
    },
    setCookiePermission(state, action) {
      if (action.payload.type === 'allowAll') {
        state.cookiePermissions = {
          googleAnalytics: true,
          googleSSO: true,
          upScope: true,
          microsoftClarity: true,
          chatra: true,
          facebookPixel: true,
          // klaviyo: true,
        };
      } else if (action.payload.type === 'onlyNecessary') {
        state.cookiePermissions = {
          googleAnalytics: false,
          googleSSO: false,
          upScope: false,
          microsoftClarity: false,
          chatra: false,
          facebookPixel: false,
          // klaviyo: false,
        };
      } else if (action.payload.type === 'custom') {
        state.cookiePermissions = action.payload.permissions;
      }
    },
  },
});

export const { setReferrerCode, setCookiePermission } = persistorSlice.actions;
export default persistorSlice.reducer;
