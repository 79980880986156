import { useMediaQuery } from '@mantine/hooks';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

const notToShowHeader = [
  '/login',
  '/help-center',
  '/forum',
  '/dashboard',
  '/landing',
  '/google/callback',
];

export default function MainHeader() {
  const router = useRouter();
  const isDesktopView = useMediaQuery('(min-width: 768px)');
  const isHomePage =
    router.pathname.startsWith('/?referrer=') || router.pathname === '/';

  if (notToShowHeader.some((value) => router.pathname.startsWith(value)))
    return null;

  if (isDesktopView === true) {
    if (isHomePage) {
      const HomeDesktopHeader = dynamic(
        () => import('./home-page-header/HomeDesktopHeader'),
        {
          loading: () => <div className={`h-16 bg-white z-[1000] shadow`} />,
        }
      );

      return <HomeDesktopHeader />;
    }

    const DesktopHeader = dynamic(() => import('./DesktopHeader'), {
      loading: () => (
        <div
          className={`h-32 bg-white z-[1000] shadow top-0 ${
            isHomePage ? 'absolute bg-transparent' : 'sticky bg-white shadow'
          }`}
        />
      ),
    });

    return <DesktopHeader />;
  }

  if (isDesktopView === false) {
    if (isHomePage) {
      const HomeMobileHeader = dynamic(
        () => import('./home-page-header/HomeMobileHeader'),
        {
          loading: () => <div className={`h-16 bg-white z-[1000] shadow`} />,
        }
      );

      return <HomeMobileHeader />;
    }

    const MobileHeader = dynamic(() => import('./MobileHeader'), {
      loading: () => (
        <div
          className={`h-16 bg-white z-[1000] shadow top-0 ${
            isHomePage ? 'absolute bg-transparent' : 'sticky bg-white shadow'
          }`}
        />
      ),
    });

    return <MobileHeader />;
  }

  return null;
}
