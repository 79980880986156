import { getCheckoutData, getCheckoutGuestData } from 'app/api/checkout';
import Router from 'next/router';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { getAllCountries } from '../api/general';
import {
  getCheckoutFail,
  getCheckoutPending,
  getCheckoutSuccess,
  getCountriesFail,
  getCountriesPending,
  getCountriesSuccess,
} from '../store/checkoutSlice';

function* getCheckoutDataFromServer() {
  try {
    const isUserLoggedIn = yield select((state) => state?.auth?.isUserLoggedIn);
    let res;
    if (isUserLoggedIn) {
      res = yield call(getCheckoutData);
    } else {
      const cartGuestItems = yield select((state) => state.cart?.cartItems);
      const has_bike = cartGuestItems.some((item) => item.type === 'bike');

      res = yield call(getCheckoutGuestData, { has_bike });
    }

    yield put(getCheckoutSuccess({ data: res.data }));
  } catch (error) {
    yield put(
      getCheckoutFail({
        error: error.response.data,
      })
    );
    Router.push('/');
  }
}

export function* handleGetCheckout() {
  yield takeEvery(getCheckoutPending.type, getCheckoutDataFromServer);
}

function* handleGetCountriesPending() {
  try {
    const res = yield call(getAllCountries());
    if (res) {
      yield put(getCountriesSuccess(res));
    }
  } catch (error) {
    const errorMessage = "Can't reach server right now.";
    yield put(
      getCountriesFail(error?.response?.data || { message: errorMessage })
    );
  }
}

export function* handleGetCountries() {
  yield takeEvery(getCountriesPending.type, handleGetCountriesPending);
}

export default function* rootSaga() {
  yield all([fork(handleGetCheckout), fork(handleGetCountries)]);
}
