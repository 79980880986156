import Head from 'next/head';
import { useRouter } from 'next/router';

const MetaHead = () => {
  const router = useRouter();

  return (
    <Head>
      <title>Nireeka Bikes | Carbon-Fiber Smart E-Bikes</title>
      <meta
        name='application-name'
        content='Nireeka'
      />
      <meta charSet='UTF-8' />
      <meta
        httpEquiv='Content-Type'
        content='text/html; charset=UTF-8'
      />
      <meta
        httpEquiv='Content-Language'
        content='en'
      />
      <link
        rel='icon'
        href='/favicon.ico'
        type='image/x-icon'
      />
      <link
        rel='shortcut icon'
        href='/favicon.ico'
        type='image/x-icon'
      />
      <link
        rel='mask-icon'
        href='/favicon.ico'
        color='#F14733'
      />
      <link
        rel='canonical'
        key='canonical'
        href='https://nireeka.com'
      />
      <meta
        name='google-site-verification'
        content='1FOrebzC378BI4t5TDdculQEHiOPqHaZImrZALOH9oA'
      />
      <meta
        name='description'
        key='description'
        content='Nireeka is an electric mobility company that redefines the biking  experience using its solid, sleek, and gorgeous design and strives to make smarter e-bikes with better performance.'
      />
      {/* <meta
        name='keywords'
        key='keywords'
        content='Nireeka, ebike, carbon fiber, affordable'
      /> */}
      <meta
        name='msvalidate.01'
        content='FACF38529D0BA7B8714CE686DF7C3A6D'
      />

      <meta
        name='apple-mobile-web-app-capable'
        content='yes'
      />
      <meta
        name='apple-mobile-web-app-status-bar-style'
        content='default'
      />
      <meta
        name='apple-mobile-web-app-title'
        content='Nireeka'
      />
      <meta
        name='format-detection'
        content='telephone=no'
      />
      <meta
        name='mobile-web-app-capable'
        content='yes'
      />
      <meta
        name='msapplication-TileColor'
        content='#f14733'
      />
      <meta
        name='msapplication-tooltip'
        content='Nireeka'
      />
      <meta
        name='msapplication-tap-highlight'
        content='no'
      />
      <meta
        name='theme-color'
        content='#f14733'
      />
      <link
        rel='mask-icon'
        href='/nireeka-red.svg'
        color='#F14733'
      />
      <meta
        name='twitter:site'
        content='https://nireeka.com'
      />
      <meta
        name='twitter:url'
        key='twitterUrl'
        content='https://nireeka.com'
      />
      <meta
        name='twitter:title'
        key='twitterTitle'
        content='Nireeka'
      />
      <meta
        name='twitter:description'
        key='twitterDescription'
        content='Nireeka is an electric mobility company that redefines the biking  experience using its solid, sleek, and gorgeous design and strives to make smarter e-bikes with better performance.'
      />
      <meta
        name='twitter:image'
        key='twitterImage'
        content='/nireeka.jpg'
      />
      <meta
        name='twitter:creator'
        content='@MaxShojaie'
      />
      {/* <meta
        name='keywords'
        key='twitterKeywords'
        content='Nireeka,ebike,Nireeka Prime,Nireeka Homie,Nireeka Nyx,Smart Ebike,carbon fiber,affordable'
      /> */}
      <meta
        property='og:type'
        key='openGraphType'
        content='website'
      />
      {/* <meta
        property='og:image'
        key='openGraphImage'
        content='https://api.nireeka.com/images/promo/nyx-promo-1.jpg'
      /> */}
      <meta
        property='og:url'
        key='openGraphUrl'
        content='https://nireeka.com'
      />
      <meta
        property='og:site_name'
        content='Nireeka'
      />
      <meta
        property='og:title'
        key='openGraphTitle'
        content='Carbon-Fiber Smart E-Bikes'
      />
      <meta
        property='og:brand'
        content='Nireeka'
      />
      <meta
        property='og:locale'
        content={router.locale}
      />
      <meta
        property='og:description'
        key='openGraphDescription'
        content='Nireeka is an electric mobility company that redefines the biking  experience using its solid, sleek, and gorgeous design and strives to make smarter e-bikes with better performance.'
      />
      <meta
        property='og:site_name'
        content='Nireeka'
      />
      {/* <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover' /> */}
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1'
      />
      <link
        rel='apple-touch-icon'
        href='/nireeka.jpg'
      />
      <link
        rel='apple-touch-icon'
        sizes='48x46'
        href='/icons/icon-48x48.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='72x72'
        href='/icons/icon-72x72.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='96x96'
        href='/icons/icon-96x96.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='128x128'
        href='/icons/icon-128x128.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='144x144.'
        href='/icons/icon-144x144.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='152x152'
        href='/icons/icon-152x152.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='192x192'
        href='/icons/icon-192x192.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='384x384'
        href='/icons/icon-384x384.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='512x512'
        href='/icons/icon-512x512.png'
      />
      <link
        rel='apple-touch-startup-image'
        href='/splash/apple-splash-1170-2532.jpg'
        media='(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='/splash/apple-splash-1179-2556.jpg'
        media='(device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='/splash/apple-splash-1284-2778.jpg'
        media='(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='/splash/apple-splash-1290-2796.jpg'
        media='(device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
      />
      <link
        rel='apple-touch-startup-image'
        href='/splash/apple-splash-2778-1284.jpg'
        media='(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
      />
    </Head>
  );
};

export default MetaHead;
