import axios from 'axios';
import AppHttp from '../AppHttp';

export const getAllCountries = async () => {
  const http = AppHttp();
  try {
    const res = await http.get('/api/countries');
    return res.data;
  } catch (error) {
    console.log('Error in fetching countries', error, error.response);
    return error;
  }
};

export const getAllCountriesCheckoutPage = async () => {
  const http = AppHttp();
  try {
    const res = await http.post('/api/countries/active');
    return res.data;
  } catch (error) {
    console.log('Error in fetching countries', error, error.response);
    throw error;
  }
};

export const getAllCountriesCheckoutPageNotLoggedIn = async (items) => {
  const http = AppHttp({ noToken: true });
  try {
    const res = await http.post('/api/countries/active', { items });
    return res.data;
  } catch (error) {
    console.log('Error in fetching countries', error, error.response);
    throw error;
  }
};

export const getAllBikes = async () => {
  const http = AppHttp({ noToken: true });
  try {
    const res = await http.get('/api/bikes/list');
    return res.data;
  } catch (error) {
    console.log('Error in fetching bike list', error, error.response);
    throw error;
  }
};

export const getShippingCostByCountryAndProduct = async (
  countryId,
  productId
) => {
  const http = AppHttp({ noToken: true });
  try {
    const res = await http.get('/api/shipping-calculator', {
      params: { country: countryId, product: productId },
    });
    return res.data;
  } catch (error) {
    console.log('Error getting shipping cost:', error, error.response);
  }
};

export const deleteFile = async (id) => {
  const http = AppHttp();
  try {
    const res = await http.post('/api/file/destroy', { file_id: id });
    return res.data;
  } catch (error) {
    console.log('Error in deleting file', error, error.response);
    return error;
  }
};

export const getAddressAutoCompleteReq = async (input, locale, countryCode) => {
  try {
    const res = await axios.post('/api/address-auto-complete', {
      input,
      locale,
      countryCode,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getGlobalAddressAutoCompleteReq = async (
  input,
  locale,
  countryCode
) => {
  try {
    const res = await axios.post('/api/global-address-auto-complete', {
      input,
      locale,
      countryCode,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getAddressDataReq = async (input, locale, countryCode) => {
  try {
    const res = await axios.post('/api/get-place-detail', {
      input,
      locale,
      countryCode,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const getNavMenuData = async (locale) => {
  const http = AppHttp();
  try {
    const res = await http.get('/api/bike-categories-menu', {
      headers: {
        params: {
          lang: locale,
        },
      },
    });
    return res.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
