import { MapPinIcon } from '@heroicons2/react/24/outline';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandX,
  IconBrandYoutube,
} from '@tabler/icons-react';
import { getBikesPending } from 'app/store/generalSlice';
import { setShowLocationModal } from 'app/store/locationSlice';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { subscribeEmail } from '../../app/api/home';
import contact from '../../public/images/contact.png';
import IconWhite from '../../public/images/icon-white.jpg';
import LocationModal from './location';

const navigation = {
  BIKES: [
    { title: 'Nireeka Prime', slug: 'nireeka-prime-a4' },
    { title: 'Nireeka Homie', slug: 'nireeka-homie-v23' },
    { title: 'Nireeka Nyx', slug: 'nyx-v1' },
  ],
  ACCESSORIES: [
    { name: 'Nireeka Helmet', to: '/accessories/5000110/nireeka-helmet' },
    { name: 'Nireeka Backpack', to: '/accessories/5000111/nireeka-backpack' },
    // { name: 'Nireeka Taillight', to: '/accessories/5000064/smart-taillight-2' },
  ],
  SUPPORT: [
    { name: 'Contact Us', to: '/contact', ariaLabel: 'Contact us' },
    { name: 'FAQ', to: '/faq', ariaLabel: 'faq' },
    {
      name: "User's Manual",
      to: '/user-manual',
      ariaLabel: "User's Manual",
    },
    { name: 'Help Center', to: '/help-center', ariaLabel: 'Help Center' },
    { name: 'Warranty', to: '/warranty', ariaLabel: 'Bikes Warranty' },
  ],
  COMPANY: [
    // { name: 'Find a dealer', to: '/dealers' },
    { name: 'About Us', to: '/about-us' },
    { name: 'Privacy Policy', to: '/privacypolicy' },
    { name: 'Terms & Conditions', to: '/terms' },
    { name: 'Refund & Return Policy', to: '/rrpolicy' },
    { name: 'Nireeka Game', to: 'https://api.nireeka.com/game' },
  ],
  WEBSITE: [
    { name: 'Log In / Register', to: '/login' },
    { name: 'Dashboard', to: '/dashboard' },
    { name: 'Forums', to: '/forum/1' },
  ],
  social: [
    {
      name: 'Facebook',
      to: 'https://www.facebook.com/nireeka/',
      icon: (props) => <IconBrandFacebook {...props} />,
    },
    {
      name: 'Instagram',
      to: 'https://instagram.com/nireeka.official',
      icon: (props) => <IconBrandInstagram {...props} />,
    },
    {
      name: 'YouTube',
      to: 'https://www.youtube.com/nireeka',
      icon: (props) => <IconBrandYoutube {...props} />,
    },
    {
      name: 'Twitter',
      to: 'https://twitter.com/nireeka1',
      icon: (props) => <IconBrandX {...props} />,
    },
  ],
};

const notToShowFooter = [
  '/dashboard',
  '/forum',
  '/login',
  '/configurator/',
  '/gravel-bikes/',
  '/mountain-bikes/',
  '/in-stock-bikes/',
  '/kids-bikes/',
  '/google/callback',
];

function Footer() {
  const router = useRouter();
  const dispatch = useDispatch();
  const isTabletView = useMediaQuery('(max-width: 1023px)');
  const bikes = useSelector((state) => state.general.bikes);
  const [valEmail, setValEmail] = useState('');
  const [isPageEmbedded, setIsPageEmbedded] = useState(false);
  const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);

  const location = useSelector((state) => state.location);

  const submitSubscribe = async (e) => {
    e.preventDefault();

    try {
      const response = await subscribeEmail({ email: valEmail });

      if (response.status) {
        toast.success(response.message);
      } else {
        toast.error('The email has already been taken.');
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Sorry, we couldn't check. Try again later.");
      }
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.self !== window.top) setIsPageEmbedded(true);
    }
    dispatch(getBikesPending());
  }, []);

  // Not rendering footer if page is embedded
  if (isPageEmbedded) return <Fragment />;

  // Not rendering footer for specific paths
  if (notToShowFooter.some((value) => router.pathname.startsWith(value)))
    return null;
  if (router.pathname.startsWith('/cart/checkout') && isTabletView) return null;

  return (
    <>
      {location?.showLocationModal && <LocationModal />}
      <footer
        className='bg-black'
        aria-labelledby='footer-heading'
      >
        <h3
          id='footer-heading'
          className='sr-only'
        >
          Footer
        </h3>
        <div className='px-4 pb-6 mx-auto max-w-7xl sm:px-6 lg:pb-8 lg:px-8'>
          <div className='flex flex-wrap-reverse mx-auto'>
            <div className='justify-end hidden w-1/2 md:flex imgMin-w'>
              <Image
                src={contact}
                alt=''
                className='w-8/12'
              />
            </div>
            <div className='items-center w-full px-4 m-auto text-gray-200 md:w-1/2 '>
              <h4 className='p-5 text-2xl font-light leading-8 text-center align-middle '>
                Customer support
                <span className='flex pt-6 mx-auto border-b-2 border-red-600 border-double w-36'></span>
              </h4>
              <p className='pt-5 font-light text-center text-gray-400 normal-case '>
                Get after-sales services from the Nireeka authorized team.
              </p>
              <p className='pt-2 font-light text-center text-gray-400 normal-case'>
                Monday - Friday - 9 A.M to 5 P.M. EST
              </p>
              <p className='pt-2 font-light text-center text-gray-400 normal-case'>
                Saturday - 9 A.M to 1 P.M. EST
              </p>
              <div className='pt-10 mx-auto font-light text-center text-gray-400 uppercase cursor-pointer text-1remi hover:text-gray-100'>
                <Link
                  href='/dashboard/support'
                  passHref
                  prefetch={false}
                >
                  <a>&gt; Find Out More</a>
                </Link>
              </div>
            </div>
          </div>
          <div className='relative mt-1'>
            <div
              className='absolute inset-0 flex items-center'
              aria-hidden='true'
            >
              <div className='w-full border-t border-nireekaBorderColor' />
            </div>
            <div className='relative flex justify-center'>
              <Image
                src={IconWhite}
                alt='nireeka'
                className='w-20 h-20'
                height={80}
                width={80}
              />
            </div>
          </div>
        </div>
        <div className='px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:py-8 lg:px-8'>
          <div className='md:grid md:grid-cols-3'>
            <div className='grid grid-cols-2 gap-8 md:col-span-2'>
              <div className='md:grid md:grid-cols-3 md:gap-4'>
                <div className='mt-0'>
                  <h4 className='text-sm font-light tracking-wider text-gray-100 uppercase'>
                    BIKES
                  </h4>
                  <ul
                    role='list'
                    className='mt-4 space-y-4'
                  >
                    {(bikes?.length ? bikes : navigation.BIKES).map(
                      (item, index) => (
                        <li key={index}>
                          <div className='text-sm text-gray-400 hover:text-white'>
                            <Link
                              href={`/configurator/${item.slug}`}
                              passHref
                              prefetch={false}
                            >
                              <a
                                className='font-light cursor-pointer'
                                // target="_blank"
                              >
                                {item?.title}
                              </a>
                            </Link>{' '}
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <div className='mt-10 md:mt-0'>
                  <h4 className='text-sm font-light tracking-wider text-gray-100 uppercase'>
                    ACCESSORIES
                  </h4>
                  <ul
                    role='list'
                    className='mt-4 space-y-4'
                  >
                    {navigation.ACCESSORIES.map((item) => (
                      <li key={item.name}>
                        <div className='text-sm text-gray-400 hover:text-white'>
                          <Link
                            href={item.to}
                            passHref
                            prefetch={false}
                          >
                            <a
                              className='font-light cursor-pointer'
                              // target="_blank"
                            >
                              {item.name}
                            </a>
                          </Link>{' '}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='md:grid md:grid-cols-1 md:gap-4'>
                  <div className='mt-12 md:mt-0'>
                    <h4 className='text-sm font-light tracking-wider text-gray-100 uppercase'>
                      SUPPORT
                    </h4>
                    <ul
                      role='list'
                      className='mt-4 space-y-4'
                    >
                      {navigation.SUPPORT.map((item) => (
                        <li key={item.name}>
                          <div className='text-sm text-gray-400 hover:text-white'>
                            <Link
                              href={item.to}
                              passHref
                              prefetch={false}
                            >
                              <a
                                className='font-light cursor-pointer'
                                aria-label={item.ariaLabel}
                                // target="_blank"
                              >
                                {item.name}
                              </a>
                            </Link>{' '}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className='md:grid md:grid-cols-2 md:gap-4'>
                <div className='mt-0'>
                  <h4 className='text-sm font-light tracking-wider text-gray-100 uppercase'>
                    COMPANY
                  </h4>
                  <ul
                    role='list'
                    className='mt-4 space-y-4'
                  >
                    {navigation.COMPANY.map((item) => (
                      <li key={item.name}>
                        <div className='text-sm text-gray-400 hover:text-white'>
                          <Link
                            href={item.to}
                            passHref
                            prefetch={false}
                          >
                            <a
                              className='font-light cursor-pointer'
                              // target="_blank"
                            >
                              {item.name}
                            </a>
                          </Link>{' '}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='md:grid md:grid-cols-1 md:gap-4'>
                  <div className='mt-12 md:mt-0'>
                    <h4 className='text-sm font-light tracking-wider text-gray-100 uppercase'>
                      WEBSITE
                    </h4>
                    <ul
                      role='list'
                      className='mt-4 space-y-4'
                    >
                      {navigation.WEBSITE.map((item) => {
                        // Check if the 'item' is trying to navigate to the '/login' page and the user is already logged in
                        // If both conditions are met, return null to prevent the navigation to the '/login' page
                        if (item.to === '/login' && isUserLoggedIn) return null;

                        return (
                          <li key={item.name}>
                            <div className='text-sm text-gray-400 hover:text-white'>
                              <Link
                                href={item.to}
                                passHref
                                prefetch={false}
                              >
                                <a
                                  className='font-light cursor-pointer'
                                  // target="_blank"
                                >
                                  {item.name}
                                </a>
                              </Link>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='mx-auto mt-8 w-fit md:mt-0'>
              <h4 className='text-sm font-light tracking-wider text-gray-100 uppercase'>
                Subscribe to our newsletter
              </h4>
              <p className='mt-4 text-sm font-light text-gray-300'>
                The latest news, articles, and resources, sent to your inbox
                weekly.
              </p>
              <form
                className='mt-4 sm:flex sm:max-w-md'
                onSubmit={submitSubscribe}
              >
                <label
                  htmlFor='email-address'
                  className='sr-only'
                >
                  Email address
                </label>
                <input
                  onChange={(e) => setValEmail(e.target.value)}
                  type='email'
                  name='email-address'
                  placeholder='Enter your email'
                  id='email-address'
                  autoComplete='email'
                  required
                  value={valEmail}
                  className='w-full min-w-0 px-4 py-2 text-base text-gray-900 placeholder-gray-500 bg-white border border-transparent rounded-md appearance-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400'
                />
                <div className='mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0'>
                  <button
                    type='submit'
                    className='flex items-center justify-center w-full px-4 py-2 text-base font-light text-white border border-white rounded-md focus:outline-none hover:text-gray-900 focus:ring hover:bg-white focus:ring-gray-200'
                  >
                    Subscribe
                  </button>
                </div>
              </form>
              <div className='relative hidden lg:flex lg:top-7'>
                {/* badge */}
                <div
                  id='surly-badge'
                  className='surly__id_124143181 surly-badge_black-gradient'
                >
                  <div className='surly-badge__header'>
                    <h4 className='surly-badge__header-title'>Brilliantly</h4>
                    <p className='surly-badge__header-text'>SAFE!</p>
                  </div>
                  <div className='surly-badge__tag'>
                    <Link href='https://sur.ly/i/nireeka.com/'>
                      <a
                        className='surly-badge__tag-text'
                        //  target="_blank"
                      >
                        nireeka.com
                      </a>
                    </Link>
                  </div>
                  <div className='surly-badge__footer'>
                    <h4 className='surly-badge__footer-title'>
                      Content &amp; Links
                    </h4>
                    <p className='surly-badge__footer-text'>
                      Verified by
                      <Link href='https://sur.ly'>
                        <a
                          // target="_blank"
                          className='px-1 surly-badge__footer-link'
                        >
                          Sur.ly
                        </a>
                      </Link>
                    </p>
                  </div>
                  <div className='surly-badge__date'>2022</div>
                </div>
                {/* badge */}
              </div>
            </div>
          </div>

          <div className='mt-4 md:flex md:items-center md:justify-between'>
            <div className='flex justify-center py-5 space-x-2 md:order-2'>
              {navigation.social.map((item) => (
                <div
                  key={item.name}
                  className='z-[1] text-base text-gray-400 md:mt-0 md:order-1'
                >
                  <div className=' front-footer-social-box'>
                    <Link
                      href={`${item.to}`}
                      passHref
                    >
                      <a
                        target='_blank'
                        aria-label={`Nireeka ${item.name}`}
                      >
                        <div className='px-1 text-gray-400 hover:text-white'>
                          <item.icon
                            className='w-6 h-6 text-lg lg:text-2xl md:w-9'
                            aria-hidden='true'
                          />
                        </div>
                      </a>
                    </Link>
                  </div>
                </div>
              ))}
            </div>

            <p className='flex flex-col mt-5 font-light text-gray-400 md:mt-0 md:order-1'>
              {/* <FooterLocation /> */}
              <span className='inline-block'>
                &copy; 2024 Nireeka Technologies Inc. All rights reserved.
              </span>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;

const FooterLocation = () => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  if (!cookies?.get('_country') || cookies?.get('_country') != 'US') {
    return <></>;
  }

  let locationName = 'select location';
  if (cookies?.get('_location_name')) {
    locationName = cookies?.get('_location_name');
  }
  return (
    <span
      className='flex h-4 cursor-pointer'
      onClick={() => dispatch(setShowLocationModal(true))}
    >
      <span className='inline-block w-4 h-4 align-top'>
        <MapPinIcon className='w-4 h-4 text-white' />
      </span>
      <span className='h-4 pl-1 text-sm leading-4 text-white align-top'>
        {locationName}
      </span>
    </span>
  );
};
