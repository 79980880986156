/**
 * Redux Store
 */

import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootSaga from '../sagas';
import authReducer from './authSlice';
import cartServerReducer from './cartServer';
import cartReducer from './cartSlice';
import checkoutReducer from './checkoutSlice';
import configuratorReducer from './configuratorSlice';
import dashboardReducer from './dashboardSlice';
import helpCenterReducer from './helpCenterSlice';
import homePageReducer from './homePageSlice';
import modalReducer from './modalSlice';
import cacheReducer from './requestCacheState';
import SpareSliceReducer from './spareSlice';
// import halloweenReducer from "./promoHalloween";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import comparisonReducer from './comparisonSlice';
import generalReducer from './generalSlice';
import locationReducer from './locationSlice';
import persistorReducer from './persistorSlice';
import stateCountryReducer from './stateCountrySlice';
import staticReducer from './staticSlice';
import uiReducer from './uiSlice';

const sagaMiddleware = createSagaMiddleware();

const comparisonPersistConfig = {
  key: 'comparison',
  version: 1,
  storage,
};

const comparisonPersistedReducer = persistReducer(
  comparisonPersistConfig,
  comparisonReducer
);

const persistorPersistConfig = {
  key: 'global',
  version: 2,
  storage,
};

const globalMigrations = {
  2: (state) => {
    return {
      ...state,
      cookiePermissions: {
        ...state.cookiePermissions,
        klaviyo: 'undefined',
      },
    };
  },
};

const persistorPersistedReducer = persistReducer(
  {
    ...persistorPersistConfig,
    // stateReconciler: autoMergeLevel2,
    // migrate: createMigrate(globalMigrations, { debug: true }),
  },
  persistorReducer
);

const store = configureStore({
  reducer: {
    stateCountry: stateCountryReducer,
    ...homePageReducer,
    // halloween: halloweenReducer,
    auth: authReducer,
    ...helpCenterReducer,
    ...SpareSliceReducer,
    configurator: configuratorReducer,
    cartServer: cartServerReducer,
    checkout: checkoutReducer,
    general: generalReducer,
    static: staticReducer,
    cart: cartReducer,
    modal: modalReducer,
    dashboard: dashboardReducer,
    cache: cacheReducer,
    comparison: comparisonPersistedReducer,
    persistor: persistorPersistedReducer,
    location: locationReducer,
    ui: uiReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
