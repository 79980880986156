import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  showLocationModal:false,
  location:'INTERNATIONAL'
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setShowLocationModal: (state, action) => {
      state.showLocationModal = action.payload;
    },
    setLocation: (state,action) => {
      state.location = action.payload;
    }
  },
});

export const {
  setShowLocationModal,
  setLocation,
} = locationSlice.actions;

export default locationSlice.reducer;
