import Link from 'next/link';
import classNames from '../../../functions/classNames';

const ConditionalLink = ({
  children,
  href,
  condition,
  className,
  noAnchor,
  target,
}) => {
  return condition && href && href !== '' ? (
    <Link
      href={href}
      passHref
      prefetch={false}
    >
      {noAnchor ? (
        <span className={classNames(className)}>{children}</span>
      ) : (
        <a
          className={classNames(className)}
          target={target}
        >
          {children}
        </a>
      )}
    </Link>
  ) : (
    <>{children}</>
  );
};

export default ConditionalLink;
