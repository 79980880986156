import { getStateCountryData } from 'app/api/checkout/stateCountry';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAddressDetailsByPlaceId } from '../../app/api/checkout/address';
import classNames from '../../functions/classNames';
import WhiteShadowCard from '../Atoms/cards/WhiteShadowCard';
import NireekaCombobox from '../Atoms/inputs/NireekaCombobox';
import SelectOrInput from '../Atoms/inputs/SelectOrInput';
import Input from '../common/Input';

export default function AddressForm(props) {
  const router = useRouter();

  const [addressSuggestions, setAddressSuggestions] = useState(null);
  const [isAddressDetailsLoading, setAddressDetailsLoading] = useState(false);

  const { countries } = props;
  const { setStateCountrySelect } = props;

  const [filteredIdState, setFilteredIdState] = useState([]);
  //state
  const [selectedStateOption, setSelectedStateOption] = useState(null);

  const [itemState, setItemState] = useState(null);

  const [showAlertModal, setShowAlertModal] = useState(false);

  const cartServerItems = useSelector(
    (state) => state?.cartServer?.cartData?.items
  );

  useEffect(() => {
    if (
      props.formik.values.state.toLowerCase() === 'california' &&
      cartServerItems.find((item) => item.category === 'Bikes')
    ) {
      setShowAlertModal(true);
    }
  }, [props.formik.values.state]);

  const handleChange = ({ name }) => {
    setSelectedStateOption(name);
    console.log(selectedStateOption);
  };

  const handleAddressSuggestionClick = async (selectedAddress) => {
    let addressDetailsResponse = null;
    try {
      setAddressDetailsLoading(true);
      addressDetailsResponse = await getAddressDetailsByPlaceId(
        selectedAddress.place_id
      );
    } catch (error) {
    } finally {
      setAddressSuggestions(null);
      props.formik.setFieldValue('city', '');
      props.formik.setFieldValue('state', '');
      props.formik.setFieldValue('zipcode', '');
      setAddressDetailsLoading(false);
    }

    if (addressDetailsResponse.data.city) {
      props.formik.setFieldValue('city', addressDetailsResponse.data.city);
      props.formik.setFieldTouched('state', false, false);
    } else if (addressDetailsResponse.data.town) {
      props.formik.setFieldValue('city', addressDetailsResponse.data.town);
      props.formik.setFieldTouched('state', false, false);
    } else if (addressDetailsResponse.data.village) {
      props.formik.setFieldValue('city', addressDetailsResponse.data.village);
      props.formik.setFieldTouched('state', false, false);
    }

    if (addressDetailsResponse.data.state) {
      props.formik.setFieldValue('state', addressDetailsResponse.data.state);
      props.formik.setFieldTouched('state', false, false);
    } else if (addressDetailsResponse.data.province) {
      props.formik.setFieldValue('state', addressDetailsResponse.data.province);
      props.formik.setFieldTouched('state', false, false);
    }

    if (addressDetailsResponse.data.postal_code) {
      props.formik.setFieldValue(
        'zipcode',
        addressDetailsResponse.data.postal_code
      );
      props.formik.setFieldTouched('zipcode', false, false);
    }

    let addressFieldContent = '';
    if (addressDetailsResponse.data.street_number)
      addressFieldContent += addressDetailsResponse.data.street_number + ' ';
    if (addressDetailsResponse.data.route)
      addressFieldContent += addressDetailsResponse.data.route;

    if (addressFieldContent === '')
      addressFieldContent = selectedAddress.full_name;

    props.formik.setFieldValue('address', addressFieldContent);
    props.formik.setFieldTouched('address', false, false);
  };

  useEffect(async () => {
    let response;
    if (props?.selectIdCountry?.id === 35 || props?.selectIdCountry?.id === 4) {
      console.log('props.selectIdCountry.id', props?.selectIdCountry?.id);

      response = await getStateCountryData(props?.selectIdCountry?.id);
      console.log(response.data);
      setFilteredIdState(response.data);
      if (setStateCountrySelect) setStateCountrySelect(response.data);
      setItemState(props?.selectIdCountry?.id);
      props.formik.setFieldValue('state', '');
    } else {
      setItemState(null);
      setSelectedStateOption(null);
      if (setStateCountrySelect) setStateCountrySelect(null);
    }
  }, [props.selectIdCountry]);

  return (
    <>
      <div
        className={classNames(props.className)}
        id='addressForm'
      >
        {/* {props.selectIdCountry && "skxm"} */}
        {selectedStateOption}
        <div>
          {props.title && <h3 className='text-xl leading-6'>{props.title}</h3>}
          {props.description && (
            <p className='mt-1 text-sm text-gray-500'>{props.description}</p>
          )}
        </div>
        <div className='grid grid-cols-1 mt-3 gap-y-6 gap-x-4 sm:grid-cols-6'>
          <div className='sm:col-span-3'>
            <Input
              formik={props.formik}
              name='name'
              label='Name'
            />
          </div>

          <div className='sm:col-span-3'>
            <Input
              formik={props.formik}
              name='lastname'
              label='Last Name'
            />
          </div>

          <div className='sm:col-span-3'>
            <Input
              formik={props.formik}
              name='phone'
              label='Phone'
            />
          </div>

          <div className='sm:col-span-3'>
            {countries && (
              <NireekaCombobox
                label='Country'
                name='country'
                list={countries}
                formik={props.formik}
                defaultSelected={props.formik.values.country}
                onSelect={props.onCountrySelect}
                setSelectIdCountry={props.setSelectIdCountry}
                disabled={countries.length === 1}
              />
            )}
          </div>

          <div
            className={`${
              props.isProfileEditModal && props.isAddress2Exists
                ? 'sm:col-span-2'
                : 'sm:col-span-4'
            }`}
          >
            <Input
              formik={props.formik}
              name='address'
              label='Address'
              autoComplete='off'
              suggestions={addressSuggestions}
              onAddressSuggestionClick={handleAddressSuggestionClick}
            />
          </div>

          {props.isProfileEditModal && props.isAddress2Exists && (
            <div className='sm:col-span-2'>
              <Input
                formik={props.formik}
                name='address2'
                label='Address 2'
                autoComplete='off'
              />
            </div>
          )}

          <div className='sm:col-span-2'>
            <Input
              formik={props.formik}
              name='unit'
              label='Unit'
              optional
            />
          </div>

          <div className='sm:col-span-2'>
            <Input
              formik={props.formik}
              name='city'
              label='City'
              isLoading={isAddressDetailsLoading}
            />
          </div>

          <div className='sm:col-span-2'>
            <SelectOrInput
              title={props.title}
              options={filteredIdState}
              value={selectedStateOption?.value} // pass the string value as value prop
              onChange={(value) => handleChange({ value: value })} // pass the value as an object
              formik={props.formik}
              name='state'
              label={
                router.locale === 'en-CA' ? 'Province' : 'State / Province'
              }
              selectIdCountry={props.selectIdCountry}
              shippingStateId={props.shippingStateId}
              condition={itemState === 35 || itemState === 4}
              setIsShippingNVatLoading={props.setIsShippingNVatLoading}
              setCalculatedVat={props.setCalculatedVat}
              setShippingFee={props.setShippingFee}
              vatReqCondition={props.vatReqCondition}
            />
            {props.formik.values.state.toLowerCase() === 'california' &&
              cartServerItems.find((item) => item.category === 'Bikes') && (
                <p className='p-1 text-xs text-red-500 '>
                  <span>
                    Since your delivery address is in CA, you need to order your
                    bike from{' '}
                  </span>
                  <a
                    className='text-blue-500'
                    href='https://nireekacalifornia.com'
                  >
                    Nireekacalifornia.com
                  </a>
                </p>
              )}
          </div>

          <div className='sm:col-span-2'>
            <Input
              formik={props.formik}
              name='zipcode'
              label='ZIP / Postal code'
              isLoading={isAddressDetailsLoading}
            />
          </div>
        </div>
      </div>
      <AlertModalForCalifornia
        message={`You'll be redirected to Nireekacalifornia.com.`}
        success={() => {
          if (window) {
            window.location.href = 'https://nireekacalifornia.com';
          }
        }}
        reject={() => setShowAlertModal(false)}
        show={showAlertModal}
        setShow={setShowAlertModal}
      />
    </>
  );
}

export function AlertModalForCalifornia({
  message,
  success,
  reject,
  show,
  setShow,
}) {
  return (
    <div
      className={`${
        show ? 'scale-100' : 'scale-0'
      } bg-[rgba(0,0,0,0.75)] fixed top-0 left-0 w-[100%] h-[100vh] z-[1000] m-0 flex items-center justify-center`}
      style={{ margin: '0' }}
    >
      <WhiteShadowCard
        className={`w-[480px] h-[280px] max-w-[95%] max-h-[95%] flex flex-col items-center justify-center transition ${
          show ? 'scale-100' : 'scale-0'
        }`}
      >
        <div className='flex flex-col gap-[80px]'>
          <p>
            <span>{`You'll be redirected to `}</span>
            <a
              href='https://nireekacalifornia.com'
              className='text-blue-600 underline'
            >
              NireekaCalifornia.com
            </a>
            .
          </p>
          <div className='flex justify-between w-1/2 mx-auto '>
            <button
              onClick={success}
              className='text-red-500 hover:text-nireekaGreen'
            >
              OK
            </button>
            <button
              onClick={reject}
              className='text-[#444] hover:text-nireekaGreen'
            >
              Stay
            </button>
          </div>
        </div>
      </WhiteShadowCard>
    </div>
  );
}
