import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  checkoutError: '',
  checkoutRadioValue: '',
  shippingFee: 0,
  calculatedVat: 0,
  grandTotal: 0,
  selectedPaymentMethod: null,
  enteredPromoCode: '',
  isExistingShippingAddressMode: false,
  isExistingBillingAddressMode: false,
  billingSameAsShipping: true,
  isCreditCheckboxSelected: false,
  checkoutData: null,
  countries: null,
  isGetCountriesLoading: false,
  getCountriesError: false,
  isGetCheckoutLoading: false,
  selectedBillingAddress: null,
  selectedShippingAddress: null,
  selectedBillingCountry: null,
  hasPendingShipment: false,
};
const checkout = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    getCheckoutPending(state) {
      state.isGetCheckoutLoading = true;
    },
    getCheckoutSuccess(state, action) {
      state.isGetCheckoutLoading = false;
      state.checkoutData = action.payload.data;
      state.checkoutError = null;
    },
    getCheckoutFail(state, action) {
      state.isGetCheckoutLoading = false;
      state.checkoutData = null;
      state.checkoutError = action.payload.error;
    },
    getCountriesPending(state) {
      state.isGetCountriesLoading = true;
    },
    getCountriesSuccess(state, action) {
      state.isGetCountriesLoading = false;
      state.getCountriesError = false;
      state.countries = action?.payload?.data;
    },
    getCountriesFail(state, action) {
      state.isGetCountriesLoading = false;
      state.getCountriesError = action?.payload?.error;
      state.countries = null;
    },
    setCheckoutData(state, action) {
      state.checkoutData = action.payload;
    },
    setShippingFee(state, action) {
      state.shippingFee = action.payload;
    },
    setCalculatedVat(state, action) {
      state.calculatedVat = action.payload;
    },
    setGrandTotal(state, action) {
      state.grandTotal = action.payload;
    },
    setSelectedPaymentMethod(state, action) {
      state.selectedPaymentMethod = action.payload;
    },
    setEnteredPromoCode(state, action) {
      state.enteredPromoCode = action.payload;
    },
    setIsExistingShippingAddressMode(state, action) {
      state.isExistingShippingAddressMode = action.payload;
    },
    toggleExistingShippingAddressMode(state) {
      state.isExistingShippingAddressMode =
        !state.isExistingShippingAddressMode;
    },
    setIsExistingBillingAddressMode(state, action) {
      state.isExistingBillingAddressMode = action.payload;
    },
    toggleExistingBillingAddressMode(state) {
      state.isExistingBillingAddressMode = !state.isExistingBillingAddressMode;
    },
    toggleIsCreditCheckboxSelected(state) {
      state.isCreditCheckboxSelected = !state.isCreditCheckboxSelected;
    },
    setBillingSameAsShipping(state, action) {
      state.billingSameAsShipping = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload;
    },
    setSelectedBillingAddress(state, action) {
      state.selectedBillingAddress = action.payload;
    },
    setSelectedShippingAddress(state, action) {
      state.selectedShippingAddress = action.payload;
    },
    setBillingCountry(state, action) {
      state.selectedBillingCountry = action.payload;
    },
    setHasPendingShipment(state, action) {
      state.hasPendingShipment = action.payload;
    },
    setCheckoutRadioValue(state, action) {
      state.checkoutRadioValue = action.payload;
    },
  },
});

export const {
  getCheckoutSuccess,
  getCheckoutPending,
  getCheckoutFail,
  getCountriesFail,
  getCountriesPending,
  getCountriesSuccess,
  setCountries,
  setCheckoutData,
  setGrandTotal,
  setCalculatedVat,
  setShippingFee,
  setSelectedPaymentMethod,
  setEnteredPromoCode,
  setIsExistingShippingAddressMode,
  setIsExistingBillingAddressMode,
  setBillingSameAsShipping,
  toggleExistingShippingAddressMode,
  toggleExistingBillingAddressMode,
  toggleIsCreditCheckboxSelected,
  setSelectedBillingAddress,
  setSelectedShippingAddress,
  setBillingCountry,
  setHasPendingShipment,
  setCheckoutRadioValue,
} = checkout.actions;

export default checkout.reducer;
