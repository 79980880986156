import CookiesService from '../../../services/CookiesService';
import { NSD_SERVER } from '../../constants/servers';
import AppHttp from '../AppHttp';

export const getPersonalData = async () => {
  const http = AppHttp();
  try {
    const res = await http.get(`/api/me`);
    return res.data;
  } catch (error) {
    console.log('Error in ME API', error, error.response);
    throw error;
  }
};

export const getUserBikes = async () => {
  const http = AppHttp({ serverURL: NSD_SERVER });
  const token = CookiesService.get('access_token');
  try {
    const res = await http.post(`/api/user/getUserBikes`, { token });
    return res.data;
  } catch (error) {
    console.log('Error in getUserBikes()', { ...error.response });
    throw error;
  }
};

export const getUserModalData = async (userId) => {
  const http = AppHttp();
  try {
    const res = await http.get(`/api/user/${userId}/info`);
    return res.data;
  } catch (error) {
    console.log('Error in getting user data', error);
    return error;
  }
};

export const toggleUserPreferredUnitSystem = async () => {
  const http = AppHttp();
  try {
    const res = await http.get('/api/set-unit');
    return res.data;
  } catch (error) {
    console.log('Error in toggling unit');
    throw error;
  }
};

export const getGeneralAffiliate = async () => {
  const http = AppHttp();
  try {
    const res = await http.get('/api/affiliate');
    return res.data.data;
  } catch (error) {
    console.log('Error in getting general affiliate');
    throw error;
  }
};
export const getOrderedListAffiliate = async () => {
  const http = AppHttp();
  try {
    const res = await http.get('/api/orders/referrer');
    return res.data?.data?.orders || [];
  } catch (error) {
    console.log('Error in getting ordered list affiliate');
    throw error;
  }
};

export const editPaypalEmail = async (email) => {
  const http = AppHttp();
  try {
    const res = await http.post('/api/register-bank-card', {
      name: 'paypal',
      email,
    });
    return res.data;
  } catch (error) {
    console.log('Error in adding card number');
    throw error;
  }
};

export const withdrawAll = async () => {
  const http = AppHttp();
  try {
    const res = await http.get('/api/request-withdrawal');
    return res.data;
  } catch (error) {
    console.log('Error in withdrawal all');
    throw error;
  }
};

export const getPaypalEmail = async () => {
  const http = AppHttp();
  try {
    const res = await http.get('/api/info-bank-card');
    return res.data;
  } catch (error) {
    console.log('Error in getting user info card');
    throw error;
  }
};

export const generateCodeRequest = async () => {
  const http = AppHttp();
  try {
    const res = await http.post('/api/generate-invitation-code');
    return res.data;
  } catch (error) {
    console.log('Error in generating code');
    throw error;
  }
};

export const countUpInvitationLinkClick = async (code) => {
  const http = AppHttp();
  try {
    const res = await http.get(`/api/click-invitation-link?referrer=${code}`);
    return res.data;
  } catch (error) {
    console.log('Error in counting click');
    throw error;
  }
};
