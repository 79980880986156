import Link from 'next/link';
import classNames from '../../../functions/classNames';

const PrimaryButton = (props) => {
  const className = classNames(
    'flex justify-center items-center shadow-sm focus:outline-none border transition-all',
    props.rounded ?? 'rounded-md',
    props.padding ?? 'px-4 py-2',
    props.fullWidth ? 'w-full' : 'w-fit',
    props.disabled
      ? 'text-gray-900 bg-white border-gray-900 cursor-not-allowed'
      : 'border-transparent text-white bg-gray-900 hover:bg-white hover:border-gray-900 hover:text-gray-900',
    props.className ?? ''
  );

  if (props.href) {
    return (
      <Link
        href={props.href}
        passHref
        prefetch={false}
      >
        <a
          className={className}
          onClick={props.onClick}
          target={props.target}
          download={props.download}
        >
          {props.children}
        </a>
      </Link>
    );
  }

  return (
    <button
      id={props.id ?? 'button'}
      title={props.title}
      form={props.form}
      type={props.type ?? 'button'}
      onClick={props.onClick}
      disabled={props.disabled}
      className={className}
      onMouseEnter={props.onMouseEnter}
      aria-label={props.ariaLabel}
    >
      {props.children}
    </button>
  );
};

export default PrimaryButton;
