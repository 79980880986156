import { CONFIGURATOR_EDIT_ORDER_MODE } from 'app/constants/configuratorModes';
import qs from 'qs';
import AppHttp from '../AppHttp';

export const getConfiguratorBikes = async (query) => {
  const http = AppHttp({ noToken: true });

  const payload = {};

  for (let property in query) {
    // Checks if property has a falsy value like "", undefined or null
    if (!query[property]) {
      continue;
    }

    // If the property was an array
    if (Array.isArray(query[property]) && query[property].length !== 0) {
      const arrayWithTruthyValue = query[property].filter((item) => !!item);
      for (let index = 0; index < arrayWithTruthyValue.length; index++) {
        payload[`${property}[${index}]`] = arrayWithTruthyValue[index];
      }
    } else payload[property] = query[property];
  }

  try {
    const res = await http.get('/api/configurator', {
      params: payload,
    });
    return res.data;
  } catch (error) {
    console.error('Error fetching spares.', error.response);
    return error;
  }
};

export const getConfiguratorBikesFilters = async (
  filters,
  accessToken,
  locale,
  slug
) => {
  const http = AppHttp({ accessToken: accessToken, isClientSideLocale: false });
  try {
    const url = `/api/bikes${slug}?${qs.stringify(filters)}`;
    const res = await http.get(url, {
      headers: {
        lang: locale,
      },
    });
    return res.data;
  } catch (error) {
    console.log('Error in getting available bikes', error, error.response);
    throw error;
  }
};

export const getBikesFilters = async (filters) => {
  const http = AppHttp({ noToken: true });
  try {
    const res = await http.get(
      `api/configurator/inventory-bikes?${qs.stringify(filters)}`
    );
    return res.data;
  } catch (error) {
    console.log('Error in getting available bikes', error, error.response);
    throw error;
  }
};

export const getConfiguratorBikeInfo = async (id) => {
  const http = AppHttp({ noToken: true });
  try {
    const res = await http.get(`api/configurator/add-compare/${id}`);
    return res.data;
  } catch (error) {
    console.log('Error in getting available bikes', error, error.response);
    throw error;
  }
};

export const getBikeConfiguratorData = async (
  slug,
  accessToken = null,
  mode = null,
  isClient = false,
  locale
) => {
  const http = AppHttp({
    accessToken,
    isClientSideLocale: isClient ? true : false,
  });

  const params = {};
  if (mode === CONFIGURATOR_EDIT_ORDER_MODE) {
    params.has_upgrade_only = true;
  }

  try {
    const res = await http.get(`/api/configurator/${slug}`, {
      params,
      headers: {
        lang: locale,
      },
    });
    return res.data;
  } catch (error) {
    console.log(
      'Error in getting bike configurator data',
      error.response.data,
      error.response
    );
    throw error;
  }
};

export const getAvailableColorsBySize = async (variationId, sizeId) => {
  const http = AppHttp({ noToken: true });
  try {
    const res = await http.get(
      `/api/configurator/colors/available/${variationId}/${sizeId}`
    );
    return res.data;
  } catch (error) {
    console.log(
      'Error in getting available colors by selected size',
      error,
      error.response
    );
    throw error;
  }
};

export const getAvailableInStockColorsBySize = async (
  variationId,
  sizeId,
  colorId
) => {
  console.log(colorId);
  const http = AppHttp({ noToken: true });
  try {
    const res = await http.get(
      `/api/configurator/colors/available/${variationId}/${sizeId}/${colorId}`
    );
    return res.data;
  } catch (error) {
    console.log(error.response);
    console.log(
      'Error in getting available colors by selected size',
      error,
      error.response
    );
    throw error;
  }
};

export const getDateBySizeAndColor = async (productId, sizeId, colorId) => {
  const http = AppHttp({ noToken: true });
  try {
    const res = await http.get(
      `/api/configurator/colors/get-date/${productId}/${sizeId}/${colorId}`
    );
    return res.data;
  } catch (error) {
    console.log(
      'Error in getting bike shipping and delivery date',
      error,
      error.response
    );
    throw error;
  }
};

export const getDateByBikeId = async (bikeId) => {
  const http = AppHttp({ noToken: true });
  try {
    const res = await http.get(
      `/api/configurator/get-date/${bikeId}`
    );
    return res.data;
  } catch (error) {
    console.log(
      'Error in getting bike shipping and delivery date',
      error,
      error.response
    );
    throw error;
  }
};

export const getPromoCodeByTotalPrice = async (bikeId, totalPrice) => {
  const http = AppHttp({ noToken: true });
  try {
    const res = await http.post(
      `/api/configurator/change-promo-code/${bikeId}`,
      {
        total_price: totalPrice,
      }
    );
    return res.data;
  } catch (error) {
    console.log(
      'Error in getting bike shipping and delivery date',
      error,
      error.response
    );
    throw error;
  }
};

export const addConfiguredBikeToCart = async (dataToPostToServer) => {
  const http = AppHttp();
  try {
    const res = await http.post(`/api/configurator/cart`, dataToPostToServer);
    return res.data;
  } catch (error) {
    console.log('Error in adding bike to shopping cart', error, error.response);
    throw error;
  }
};

export const sendRateBike = async (dataToPostToServer) => {
  const http = AppHttp();
  try {
    const res = await http.post(`/api/configurator/rate`, dataToPostToServer);
    return res.data;
  } catch (error) {
    console.log('Error in send rate of bike', error, error.response);
    throw error;
  }
};

export const sendReviewBike = async (formData) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const http = AppHttp({ headers });
  try {
    const res = await http.post('/api/configurator/review', formData);
    return res.data;
  } catch (error) {
    console.log('Error sending review', error.response);
    throw error;
  }
};

export const editReviewBike = async (formData, reviewId) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const http = AppHttp({ headers });
  try {
    const res = await http.post(
      `/api/configurator/review/${reviewId}`,
      formData
    );
    return res.data;
  } catch (error) {
    console.log('Error editing review', error.response);
    throw error;
  }
};

export const deleteReviewBike = async (reviewId) => {
  const http = AppHttp();
  try {
    const res = await http.delete(`/api/configurator/review/${reviewId}`);
    return res.data;
  } catch (error) {
    console.log('Error deleting review', error.response);
    throw error;
  }
};

export const getQuickViewBikeModal = async (productId, sizeId, colorId) => {
  const http = AppHttp({ noToken: true });
  try {
    const res = await http.get(
      `/api/configurator/quick-view/${productId}` ///${sizeId}/${colorId}
    );
    return res.data;
  } catch (error) {
    console.log(
      'Error in getting bike shipping and delivery date',
      error,
      error.response
    );
    throw error;
  }
};

export const frameInventorySubscribe = async (data) => {
  const http = AppHttp();

  try {
    const response = await http.post('/api/frame-inventory/subscribe', data);
    return response.data;
  } catch (error) {
    console.error(
      'Error subscribing to frame inventory.',
      error,
      error.response
    );
    throw error;
  }
};
