import Layouts from '@/components/Layouts';
import CustomScript from '@/components/seo/CustomScript';
import MetaHead from '@/components/seo/MetaHead';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import store from '../app/store/store';

import 'react-toastify/dist/ReactToastify.css';
// import 'tailwindcss/tailwind.css';
import '../styles/globals.css';

function MyApp({ Component, pageProps }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  );
  let persistor = persistStore(store);

  return (
    <>
      <MetaHead />
      <Provider store={store}>
        <PersistGate
          persistor={persistor}
          loading={null}
        >
          {/* for SSR page source rendering. Don't remove arrow function */}
          {() => (
            // <ModalProvider>
            <>
              <CustomScript />
              <ToastContainer
                autoClose={3000}
                newestOnTop={true}
                limit={5}
              />
              <QueryClientProvider client={queryClient}>
                <Layouts>
                  <Component {...pageProps} />
                </Layouts>
              </QueryClientProvider>
            </>
            // </ModalProvider>
          )}
        </PersistGate>
      </Provider>
    </>
  );
}

export default MyApp;
