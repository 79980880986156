import { IconArrowLeft, IconReload } from '@tabler/icons-react';
import axios from 'axios';
import Image from 'next/image';
import React from 'react';
import cookieService from 'services/CookiesService';
import troubleShootingGif from '../../public/images/Computer troubleshooting.gif';
import PrimaryButton from '../Atoms/buttons/PrimaryButton';
import SecondaryButton from '../Atoms/buttons/SecondaryButton';

const isProduction = process.env.NODE_ENV === 'production';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    if (isProduction) {
      const sendLog = async () => {
        const log = {
          message: error.message,
          metadata: {
            url: window.location.href,
            userAgent: navigator.userAgent,
            error: error.message,
            errorInfo: errorInfo,
            access_token: cookieService.get('access_token') || 'guest user',
            screenSize: `${window?.screen?.width}px (width) x ${window?.screen?.height}px (height)`,
          },
        };

        await axios.post('https://api.logflare.app/logs', log, {
          params: {
            api_key: process.env.NEXT_PUBLIC_LOGFLARE_API_KEY,
            source: process.env.NEXT_PUBLIC_LOGFLARE_SOURCE_ID,
          },
        });
      };
      sendLog();
    }
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='w-full pb-20 h-fit'>
          <div className='flex flex-col items-center w-full h-full max-w-3xl mx-auto'>
            <div className=''>
              <Image
                src={troubleShootingGif}
                alt='Computer troubleshooting'
                width={300}
                height={300}
                objectFit='contain'
                priority
              />
            </div>
            <div className='flex flex-col w-full gap-5 px-7 md:px-0'>
              <h2 className='text-xl font-semibold'>
                🔍 Oops! Something Unexpected Happened
              </h2>
              <p>
                We apologize, but it seems like there&apos;s been an unexpected
                hiccup. Our team is already on it, working tirelessly to fix the
                issue and get things back on track.
              </p>
              <p>In the meantime, here are a couple of things you can try:</p>
              <ol className='font-semibold list-decimal'>
                <li>
                  Refresh the Page: Sometimes, a simple refresh can do wonders
                  and get everything back to normal.
                </li>
                <li className='mt-2'>
                  Check Your Internet Connection: Ensure you&apos;re connected
                  to the internet, and try reloading the page.
                </li>
              </ol>
              <p>
                Rest assured, we&apos;re committed to providing you with the
                best experience possible, and we appreciate your patience as we
                resolve this issue.
              </p>
              <p>
                If you continue to encounter problems or have any questions,
                feel free to reach out to our support team at{' '}
                <a
                  className='font-semibold text-blue-600 underline transition-all underline-offset-4 hover:text-blue-800'
                  href='mailto:info@nireeka.com'
                >
                  info@nireeka.com
                </a>
                .
              </p>
              <p>Thank you for your understanding!</p>
              <div className='flex flex-col justify-center gap-5 mt-5 xs:flex-row'>
                <PrimaryButton
                  className='!w-full sm:!w-fit'
                  onClick={() => {
                    this.setState({ hasError: false });
                    window.location.href = '/';
                  }}
                >
                  <IconArrowLeft className='w-5 h-5 mr-2' />
                  Back to previous page
                </PrimaryButton>
                <SecondaryButton
                  className='!w-full sm:!w-fit'
                  onClick={() => {
                    this.setState({ hasError: false });
                    window.location.reload();
                  }}
                >
                  <IconReload className='w-5 h-5 mr-2' />
                  Refresh Page
                </SecondaryButton>
              </div>
            </div>
          </div>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
