// import Cookies from "universal-cookie";
import qs from 'qs';
import AppHttp from '../AppHttp';

export const getHomeConfiguratorData = async (locale) => {
  const http = AppHttp({ isClientSideLocale: false });

  try {
    const res = await http.get(`/api/bikes/build`, {
      headers: {
        lang: locale,
      },
    });
    return res.data;
  } catch (error) {
    console.log("Couldn't get homepage data:", error, error.response);
    throw error;
  }
};

export const getHomeInventoryBikesData = async (location = null, locale) => {
  let headers = location ? location : null;

  headers = { ...headers, lang: locale };

  const http = AppHttp({
    headers: headers?.region_code && headers,
    isClientSideLocale: false,
  });

  let apiUrl = `/api/bikes/inventory`;

  if (headers?.region_code) {
    apiUrl = `/api/bikes/inventory?region_code=${location.region_code}`;
  }

  try {
    const res = await http.get(apiUrl, headers);
    return res.data;
  } catch (error) {
    console.log("Couldn't get homepage data:", error, error.response);
    throw error;
  }
};

export const getInventoryItems = async (locale, filters) => {
  const http = AppHttp({
    isClientSideLocale: false,
  });
  try {
    const res = await http.get(
      `/api/bikes/inventory/all?${qs.stringify(filters)}`,
      {
        headers: {
          lang: locale,
        },
      }
    );
    return res.data.data;
  } catch (error) {
    console.log("Couldn't get homepage data:", error, error.response);
    throw error;
  }
};

export const getHomePageData = async () => {
  const http = AppHttp();

  try {
    const res = await http.get(`/api/home-page`);
    return res.data;
  } catch (error) {
    console.log("Couldn't get homepage data:", error, error.response);
    throw error;
  }
};

export const getStatisticsData = async () => {
  const http = AppHttp();

  try {
    const res = await http.get(`/api/statistics`);
    return res.data;
  } catch (error) {
    console.log("Couldn't get statistics data:", error, error.response);
    throw error;
  }
};

export const subscribeEmail = async (data) => {
  const http = AppHttp({ noToken: true });

  try {
    const res = await http.post(`/api/newsletter/subscribe`, data);
    return res.data;
  } catch (error) {
    console.error('Error checking contact-Us.', error, error.response);

    return error;
  }
};

export const getHomePageDataSlider = async () => {
  const http = AppHttp();

  try {
    const res = await http.get(`/api/slider-hero`);
    return res.data;
  } catch (error) {
    console.log("Couldn't get homepage data:", error, error.response);
    throw error;
  }
};

export const getHomeCategories = async () => {
  const http = AppHttp();

  try {
    const res = await http.get('/api/bike-categories');
    return res.data.data;
  } catch (error) {
    throw error;
  }
};
export const getHomeComments = async () => {
  const http = AppHttp();

  try {
    const res = await http.get('/api/latest-reviews');
    return res.data.data.items;
  } catch (error) {
    throw error;
  }
};
export const getHomeYtVideos = async () => {
  const http = AppHttp();

  try {
    const res = await http.get(
      `https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&id=V_jee2qbY6c%2C0Y860zvmUF8%2CwuEqSyqE_4I%2CohKhrea5bbo%2CMNgB9BSZ6Mw&key=${process.env.NEXT_PUBLIC_GOOGLE_JAVASCRIPT_API_KEY}`
    );
    return res.data.items;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getHomeShopByModel = async (locale) => {
  const http = AppHttp({ isClientSideLocale: false });

  try {
    const res = await http.get('/api/slider-shop-model', {
      headers: {
        lang: locale,
      },
    });
    return res.data.data;
  } catch (error) {
    throw error;
  }
};
