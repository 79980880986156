import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { default as CookiesService } from '../../../../services/CookiesService';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';

const CanadaRedirectModal = () => {
  const router = useRouter();
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    setTimeout(() => {
      if (
        CookiesService.get('show-redirect-modal') === 'true' &&
        router.locale === 'en-CA'
      ) {
        open();
      }
    }, 3000);
  }, [router.locale]);

  return (
    <Modal
      opened={opened}
      centered
      onClose={() => {
        CookiesService.remove('show-redirect-modal');
        close();
      }}
      radius='md'
      title='Redirect to Nireeka Canada?'
      zIndex={1001}
      classNames={{
        title: '!text-2xl !font-semibold',
        content: '!max-h-none',
        root: '[&_*]:!font-["Open Sans"]',
        inner: `!overflow-y-auto`,
      }}
      aria-hidden
    >
      <div className='h-full'>
        <p>
          We&apos;ve detected that you&apos;re browsing from Canada. Would you
          like to visit Nireeka Canada for a better shopping experience,
          including local prices and faster shipping?
        </p>
        <div className='flex flex-col-reverse items-center justify-between w-full gap-4 mt-5 sm:flex-row'>
          <SecondaryButton
            onClick={() => {
              CookiesService.remove('show-redirect-modal');
              close();
            }}
            fullWidth
          >
            Stay Here
          </SecondaryButton>
          <PrimaryButton
            href='https://nireeka.ca'
            fullWidth
            onClick={() => {
              CookiesService.remove('show-redirect-modal');
            }}
          >
            Go to{' '}
            <span className='underline ms-2 underline-offset-4'>
              {' '}
              Nireeka.ca
            </span>
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default CanadaRedirectModal;
