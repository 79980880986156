import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  db_id: 0,
  bikes: [],
};

const comparisonSlice = createSlice({
  initialState,
  name: 'comparison',
  reducers: {
    setBikes(state, action) {
      state.bikes = action.payload;
    },
    addBike(state, action) {
      state.bikes.push({ db_id: state.db_id, ...action.payload });
      state.db_id = state.db_id + 1;
    },
    removeBike(state, action) {
      if (state.bikes.length === 1) {
        state.bikes = [];
        state.db_id = 0;
      } else
        state.bikes = state.bikes.filter(
          (bike) => bike.db_id !== action.payload
        );
    },
    emptyComparisonBikes(state) {
      state.bikes = [];
      state.db_id = 0;
    },
  },
});

export const { setBikes, addBike, removeBike, emptyComparisonBikes } =
  comparisonSlice.actions;
export default comparisonSlice.reducer;
