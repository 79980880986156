import { XMarkIcon } from '@heroicons2/react/24/outline';
import { setShowLocationModal } from 'app/store/locationSlice';
import { useDispatch } from 'react-redux';
// import { MapPinIcon } from "@heroicons2/react/24/solid"
import Image from 'next/image';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { AlertModalForCalifornia } from '../CheckOut/AddressForm';

const mainAmericanStates = [
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
];

const americanStates = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },

  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

const LocationModal = () => {
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(setShowLocationModal(false));
  };
  const onClose = () => {
    if (window) {
      document.body.style.overflow = 'auto';
    }
  };
  useEffect(() => {
    if (window) {
      document.body.style.overflow = 'hidden';
    }
    () => onclose();
  }, []);

  const [showAlertModal, setShowAlertModal] = useState(false);

  return (
    <>
      <div className='w-full h-full bg-[rgba(0,0,0,.8)] fixed top-0 left-0 z-[1000] overflow-auto flex justify-center items-center'>
        <div className='w-full h-full pb-20 mx-auto overflow-auto bg-white lg:max-w-3/4 lg:max-h-1800 lg:rounded-xl'>
          {/** header */}
          <div className='w-full h-16 md:h-32 lg:h-24 flex p-0 md:p-4 md:pt-4 px-2 md:px-8 max-w-[1280px] mx-auto'>
            <div className='items-center hidden h-full md:flex w-44'>
              <Image
                alt='nireeka'
                src='/images/logo_nireeka_dark.jpg'
                width={170}
                height={25}
              />
            </div>

            <div className='flex-grow '>
              <h3 className='h-12 md:h-16 leading-[64px] md:text-center text-lg md:text-2xl lg:text-3xl'>
                Select Your State
              </h3>
              <p className='text-xs sm:text-sm md:text-base md:text-center text-l mt-[-6px] lg:mt-[-28px] text-gray-700'>
                Your location data will be stored in your browser cookie.
              </p>
            </div>

            <div className='flex items-center justify-end w-12 h-full md:w-44'>
              <button
                onClick={() => {
                  closeModal();
                  onClose();
                }}
                className='w-6 h-6 text-center transition border-2 border-gray-900 rounded-full md:w-8 md:h-8 hover:border-red-400'
              >
                <XMarkIcon className=' icon-stroke-width-1 mx-auto text-gray-900 transition hover:text-red-400 w-full h-full p-0.5 ' />
              </button>
            </div>
          </div>

          {/** state list */}
          <div className='w-full flex p-1 md:p-4  md:pt-12 lg:pt-4 md:px-8 max-w-[1280px] mx-auto flex-wrap '>
            <StateGrid
              states={mainAmericanStates}
              setShowAlertModal={setShowAlertModal}
            />
            <div className='bg-gray-300 w-full h-[1px]'></div>
            <StateGrid
              states={americanStates}
              setShowAlertModal={setShowAlertModal}
            />
          </div>
        </div>
        {
          <AlertModalForCalifornia
            message={`You'll be redirected to Nireekacalifornia.com.`}
            success={() => {
              if (window) {
                window.location.href = 'https://nireekacalifornia.com';
              }
            }}
            reject={() => {
              window.location.reload();
            }}
            show={showAlertModal}
            setShow={setShowAlertModal}
          />
        }
      </div>
    </>
  );
};

export default LocationModal;

const StateGrid = ({ states, setShowAlertModal }) => {
  return (
    <>
      {states.map((state, index) => (
        <div
          key={index}
          className='w-1/3 px-1 py-4 md:w-1/4 lg:w-1/4 xl:w-1/4 md:px-4 md:border-0'
        >
          <div
            className='flex w-full h-12 cursor-pointer '
            onClick={async () => {
              const cookie = new Cookies();
              const result = await cookie.set(
                '_location',
                `US-${state.abbreviation}`,
                { maxAge: 1 * 365 * 24 * 60 * 60 }
              );
              const result2 = await cookie.set(
                '_location_name',
                `${state.name}`,
                { maxAge: 1 * 365 * 24 * 60 * 60 }
              );
              if (state.name === 'California') {
                setShowAlertModal(true);
              } else {
                window.location.reload();
              }
            }}
          >
            <div className='flex flex-col justify-center flex-grow w-12 '>
              <h3 className='text-sm leading-5 md:text-base'>{state.name}</h3>
              <span className='text-xs leading-3 text-gray-400'>
                US-{state.abbreviation}
              </span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
