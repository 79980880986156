import Script from 'next/script';
import { useSelector } from 'react-redux';

const CustomScript = () => {
  const cookiePermissions = useSelector(
    (state) => state.persistor.cookiePermissions
  );

  return (
    <>
      {cookiePermissions.googleAnalytics && (
        <Script
          id='google-tag-manager-init'
          // strategy='beforeInteractive'
        >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER}');`}</Script>
      )}
      {cookiePermissions.microsoftClarity && (
        <Script
          id='microsoftClarityBehtiam'
          // strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window,document,"clarity","script","g3in3ej374");`,
          }}
        />
      )}
      {cookiePermissions.googleAnalytics && (
        <Script
          async
          strategy='lazyOnload'
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        />
      )}
      {cookiePermissions.googleAnalytics && (
        <Script
          strategy='lazyOnload'
          id='google-analytics-script-1'
        >{`window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments);}gtag('js',new Date());gtag('config','${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}',{page_path:window.location.pathname,});`}</Script>
      )}
      {cookiePermissions.chatra && (
        <Script
          id='chatra-script'
          // strategy='afterInteractive'
        >{`(function(d,w,c){w.ChatraID='NZssfAep3fjPHCpbr';var s=d.createElement('script');w[c]=w[c]||function(){(w[c].q=w[c].q||[]).push(arguments)};s.async=true;s.src='https://call.chatra.io/chatra.js';if(d.head)d.head.appendChild(s);})(document,window,'Chatra');`}</Script>
      )}
      {cookiePermissions.upScope && (
        <Script
          id='upscope-script-1'
          // strategy='afterInteractive'
        >{`(function(w,u,d){var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://code.upscope.io/VfeqdjyqR3.js';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(typeof u!=="function"){w.Upscope=i;l();}})(window,window.Upscope,document);Upscope('init');`}</Script>
      )}
      {/* <Script
        id='upscope-script-2'
        // strategy='beforeInteractive'
      >{`Upscope("updateConnection",{uniqueId:undefined,identities:[],});`}</Script> */}
      {cookiePermissions.googleAnalytics && (
        <Script
          id='google-analytics-script'
          strategy='beforeInteractive'
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        />
      )}
      {cookiePermissions.googleAnalytics && (
        <Script
          id='googleTagNireeka'
          // strategy='beforeInteractive'
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments);}gtag('js',new Date());gtag('config','${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');`,
          }}
        />
      )}
      {/* <Script
        id='affirm'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `_affirm_config = {
            public_api_key: "7T4UFS702QIN7E7X",
            script: "https://cdn1.affirm.com/js/v2/affirm.js",
            locale: "en_CA",
            country_code: "CAN",
          };
        
          (function(m,g,n,d,a,e,h,c){var b=m[n]||{},k=document.createElement(e),p=document.getElementsByTagName(e)[0],l=function(a,b,c){return function(){a[b]._.push([c,arguments])}};b[d]=l(b,d,"set");var f=b[d];b[a]={};b[a]._=[];f._=[];b._=[];b[a][h]=l(b,a,h);b[c]=function(){b._.push([h,arguments])};a=0;for(c="set add save post open empty reset on off trigger ready setProduct".split(" ");a<c.length;a++)f[c[a]]=l(b,d,c[a]);a=0;for(c=["get","token","url","items"];a<c.length;a++)f[c[a]]=function(){};k.async=
            !0;k.src=g[e];p.parentNode.insertBefore(k,p);delete g[e];f(g);m[n]=b})(window,_affirm_config,"affirm","checkout","ui","script","ready","jsReady");`,
        }}
      /> */}
      {cookiePermissions.facebookPixel && (
        <Script
          id='facebook-pixel-init'
          // strategy='beforeInteractive'
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '943260484089094'); fbq('track', 'PageView');`,
          }}
        />
      )}
      {/* {cookiePermissions.klaviyo === true && ( */}
      <Script
        id='klaviyo'
        // strategy='beforeInteractive'
        src='https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=WzcyCn'
      />
      {/* )} */}
    </>
  );
};

export default CustomScript;
