import AppHttp from '../AppHttp';

export const getAddressDetailsByPlaceId = async (placeId) => {
  const http = AppHttp();
  try {
    const res = await http.get(`/api/checkout/address/place/${placeId}`);
    return res.data;
  } catch (error) {
    console.log('Error in getting place id details:', error, error.response);
    throw error;
  }
};
