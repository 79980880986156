import { toast } from 'react-toastify';
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  deleteReviewBike,
  editReviewBike,
  getAvailableColorsBySize,
  getAvailableInStockColorsBySize,
  getBikesFilters,
  getConfiguratorBikeInfo,
  getConfiguratorBikesFilters,
  getDateByBikeId,
  getDateBySizeAndColor,
  sendRateBike,
  sendReviewBike,
} from '../api/configurator';
import { COLOR_TYPES } from '../constants/colorTypes';
import {
  callGetAvailableColorsBySize,
  callGetDateBySizeAndColor,
  deleteReviewFail,
  deleteReviewPending,
  deleteReviewSuccess,
  getBikesFiltersFail,
  getBikesFiltersPending,
  getBikesFiltersSuccess,
  getCompareBikeInfoFail,
  getCompareBikeInfoPending,
  getCompareBikeInfoSuccess,
  sendRateFail,
  sendRatePending,
  sendRateSuccess,
  sendReviewFail,
  sendReviewPending,
  sendReviewSuccess,
  setAvailableColors,
  setDates,
  setSelectedColor,
} from '../store/configuratorSlice';

function* handleGetAvailableColorsBySize(action) {
  console.log(action.payload.colorId);
  try {
    let response;

    if (action.payload.type === 'in-stock')
      response = yield call(
        getAvailableInStockColorsBySize,
        ...[
          action.payload.variationId,
          action.payload.sizeId,
          action.payload.colorId,
        ]
        // ...[action.payload.variationId, action.payload.sizeId]
      );
    else
      response = yield call(
        getAvailableColorsBySize,
        ...[action.payload.variationId, action.payload.sizeId]
        // ...[action.payload.variationId, action.payload.sizeId]
      );

    const availableColors = response.available;
    yield put(setAvailableColors(availableColors));

    const preUpgradesColorId = yield select(
      (state) => state.configurator.preUpgrades?.color_id
    );

    // Initializing color
    let colorToBeSelected = null;
    for (let i = 0; i < COLOR_TYPES.length; i++) {
      if (
        !availableColors[COLOR_TYPES[i]] ||
        availableColors[COLOR_TYPES[i]].length === 0
      )
        continue;

      if (
        !colorToBeSelected &&
        availableColors[COLOR_TYPES[i]][0].in_stock === 1
      )
        colorToBeSelected = availableColors[COLOR_TYPES[i]][0];

      // Finding pre upgrade color id in new available colors
      if (preUpgradesColorId) {
        for (let color of availableColors[COLOR_TYPES[i]]) {
          if (color.id === preUpgradesColorId) {
            colorToBeSelected = color;
            i = COLOR_TYPES.length; // Breaking the outer for loop
            break;
          }
        }
      }
    }
    console.log(colorToBeSelected);
    if (colorToBeSelected) {
      yield put(setSelectedColor(colorToBeSelected));
      yield put(
        callGetDateBySizeAndColor({
          productId: action.payload.productId,
          sizeId: action.payload.sizeId,
          colorId: colorToBeSelected.id,
        })
      );
    } else {
      yield put(setSelectedColor(null));
    }
  } catch (error) {
    yield put(setAvailableColors({}));
  }
}

export function* watchGetAvailableColorsBySize() {
  yield takeLatest(
    callGetAvailableColorsBySize.type,
    handleGetAvailableColorsBySize
  );
}

function* handleGetDateBySizeAndColor(action) {
  const inStockBikeId = yield select(
    (state) => state.configurator.configuratorData?.bike_id
  );
  console.log(action.payload);
  try {
    if (inStockBikeId) {
      const response = yield call(getDateByBikeId, ...[inStockBikeId]);

      yield put(setDates(response));
    } else {
      const response = yield call(
        getDateBySizeAndColor,
        ...[
          action.payload.productId,
          action.payload.sizeId,
          action.payload.colorId,
        ]
      );

      yield put(setDates(response));
    }
  } catch (error) {
    console.log(error);
    yield put(setDates(null));
  }
}

export function* watchGetDateBySizeAndColor() {
  yield takeLatest(callGetDateBySizeAndColor.type, handleGetDateBySizeAndColor);
}

function* handleGetBikesFiltersPending(action) {
  try {
    const res = yield call(
      action?.payload?.isInventory
        ? getBikesFilters
        : getConfiguratorBikesFilters,
      action?.payload?.queryObject,
      undefined,
      undefined,
      action?.payload?.slug
    );
    if (res) {
      yield put(getBikesFiltersSuccess(res));
    }
  } catch (error) {
    const errorMessage = "Can't reach server right now.";
    yield put(
      getBikesFiltersFail(error?.response?.data || { message: errorMessage })
    );
  }
}

export function* handleGetCountries() {
  yield takeEvery(getBikesFiltersPending.type, handleGetBikesFiltersPending);
}

function* handleConfiguratorCompareInfo(action) {
  try {
    const res = yield call(getConfiguratorBikeInfo, action?.payload?.id);
    if (res) {
      yield put(getCompareBikeInfoSuccess(res));
      if (action?.payload.onSuccess) action?.payload.onSuccess(res?.data);
    }
  } catch (error) {
    const errorMessage = "Can't reach server right now.";
    yield put(
      getCompareBikeInfoFail(error?.response?.data || { message: errorMessage })
    );
  }
}

export function* handleGetConfiguratorCompareInfo() {
  yield takeEvery(
    getCompareBikeInfoPending.type,
    handleConfiguratorCompareInfo
  );
}

function* handleSendRatePending(action) {
  try {
    const res = yield call(sendRateBike, action?.payload);
    if (res) {
      yield put(sendRateSuccess(res?.data));
      toast.success(res?.message || 'Your Rate sent successfully.');
    }
  } catch (error) {
    const errorMessage = "Can't reach server right now.";
    yield put(sendRateFail(error?.response?.data || { message: errorMessage }));
    if (!!error?.response?.data?.message)
      toast.error(error?.response?.data?.message);
  }
}

export function* watchSendRate() {
  yield takeEvery(sendRatePending.type, handleSendRatePending);
}

function* handleSendReviewPending(action) {
  try {
    const res = yield call(
      !!action?.payload?.review ? editReviewBike : sendReviewBike,
      action?.payload?.data,
      action?.payload?.review?.id
    );
    if (res) {
      yield put(sendReviewSuccess(action?.payload?.review));
      if (action?.payload.onSuccess) action?.payload.onSuccess();
      toast.success(res?.message || 'Your Review sent successfully.');
    }
  } catch (error) {
    const errorMessage = "Can't reach server right now.";
    yield put(
      sendReviewFail(error?.response?.data || { message: errorMessage })
    );
    if (!!error?.response?.data?.message)
      toast.error(error?.response?.data?.message);
    else if (error?.response?.data?.errors?.body?.length)
      toast.error(error?.response?.data?.errors?.body?.join('.'));
  }
}

export function* watchSendReview() {
  yield takeEvery(sendReviewPending.type, handleSendReviewPending);
}

function* handleDeleteReviewPending(action) {
  try {
    const res = yield call(deleteReviewBike, action?.payload?.id);
    if (res) {
      yield put(deleteReviewSuccess(action?.payload));
    }
  } catch (error) {
    const errorMessage = "Can't reach server right now.";
    yield put(
      deleteReviewFail(error?.response?.data || { message: errorMessage })
    );
    if (!!error?.response?.data?.message)
      toast.error(error?.response?.data?.message);
  }
}

export function* watchDeleteReview() {
  yield takeEvery(deleteReviewPending.type, handleDeleteReviewPending);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAvailableColorsBySize),
    fork(watchGetDateBySizeAndColor),
    fork(handleGetCountries),
    fork(handleGetConfiguratorCompareInfo),
    fork(watchSendRate),
    fork(watchSendReview),
    fork(watchDeleteReview),
  ]);
}
