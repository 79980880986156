import Input from '@/components/common/Input';
import {
  getShippingAndVatByCountry,
  getShippingAndVatGuestByCountry,
} from 'app/api/checkout';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SelectBox from './SelectedBox';

const SelectOrInput = (props) => {
  const isUserLoggedIn = useSelector((state) => state?.auth.isUserLoggedIn);
  const cartServerItems = useSelector(
    (state) => state?.cartServer?.cartData?.items
  );
  const cartGuestItems = useSelector((state) => state?.cart?.cartItems);
  const [fillValue, setFillValue] = useState('');

  const cartItems = useMemo(
    () =>
      isUserLoggedIn && cartServerItems?.length
        ? cartServerItems
        : cartGuestItems,
    [cartServerItems, cartGuestItems]
  );

  const handleChange = async (selectedOption) => {
    if (
      props.title === 'Shipping Address' &&
      props.selectIdCountry?.id === 35
    ) {
      if (!props.selectIdCountry) return;
      try {
        props.setIsShippingNVatLoading(true);
        let response;
        if (isUserLoggedIn)
          response = await getShippingAndVatByCountry(
            props.selectIdCountry?.id,
            selectedOption?.id
          );
        else {
          const items = cartItems?.map(
            (product) =>
              product?.server || {
                product_id: product?.id,
                count: product?.cartQuantity,
              }
          );
          response = await getShippingAndVatGuestByCountry(
            props.selectIdCountry?.id,
            selectedOption?.id,
            items
          );
        }

        props.setCalculatedVat(response.data.vat);
        props.setShippingFee(response.data.shipping);
      } catch (error) {
        console.log('Error in message field:', error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error(
            "Sorry, we couldn't retrieve shipping and vat fees from server."
          );
        }
      } finally {
        props.setIsShippingNVatLoading(false);
      }
    }
    props.formik.setFieldValue(props.name, selectedOption.name);
    props.formik.setFieldValue('state_id', selectedOption.id);
  };

  const isAddressDetailsLoading = false; // Replace with your logic for loading state options

  return (
    <>
      {/* id 35 => united state || id =>4 canada */}
      {props.condition ? (
        <div className='self-end sm:col-span-2'>
          <SelectBox
            options={props.options}
            value={props.value}
            onChange={handleChange}
            formik={props.formik}
            name={props.name}
            label={props.label}
            setFillValue={setFillValue}
          />
          {!props.noFormik &&
            props.formik.errors[props.name] &&
            props.formik.touched[props.name] && (
              <div
                id='formError'
                className='mx-1 mt-0.5 text-sm font-light text-red-500'
              >
                {props.formik.errors[props.name]}
              </div>
            )}
        </div>
      ) : (
        <div className={`sm:col-span-2`}>
          <Input
            showError={true}
            value={fillValue}
            formik={props.formik}
            name={props.name}
            label={props.label}
            isLoading={isAddressDetailsLoading}
          />
          {!props.noFormik &&
            props.formik.errors[props.name] &&
            props.formik.touched[props.name] && (
              <div
                id='formError'
                className='mx-1 mt-0.5 text-sm font-light text-red-500'
              >
                {props.formik.errors[props.name]}
              </div>
            )}
        </div>
      )}
      {/* Errors */}
    </>
  );
};

export default SelectOrInput;
