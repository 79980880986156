import { createSlice } from '@reduxjs/toolkit';
import { cartKeys } from 'app/constants/localStorageKeys';
import { toast } from 'react-toastify';

// code for save local storage on next js
const data =
  typeof window !== 'undefined' && localStorage.getItem(cartKeys.CART_ITEMS)
    ? JSON.parse(localStorage.getItem(cartKeys.CART_ITEMS))
    : [];
// code for save local storage on next js

const initialState = {
  isOpenModalCart: false,
  cartItems: data,
  // not work to next js  localStorage.getItem(cartKeys.CART_ITEMS)? (JSON.parse(localStorage.getItem(cartKeys.CART_ITEMS))):[],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
  isCartOpen: false,
};
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartItems: (state, action) => {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.product.id
      );

      state.cartItems[itemIndex].cartQuantity = action.payload.val;
      localStorage.setItem(
        cartKeys.CART_ITEMS,
        JSON.stringify(state.cartItems)
      );
    },
    addToCart(state, action) {
      if (action.payload.product?.category === 'Bikes') {
        const tempProduct = {
          ...action.payload,
          cartQuantity: 1,
          total_price: action.payload?.price,
        };

        toast.success(
          `${action.payload.title || 'The item'} was added to cart`
        );
        state.cartItems.push(tempProduct);
      } else {
        const itemIndex = state.cartItems.findIndex(
          (item) =>
            item.id === action.payload.id &&
            item.locale === action.payload.locale
        );
        if (itemIndex >= 0) {
          state.cartItems[itemIndex].cartQuantity += 1;
          if (state.cartItems[itemIndex].server)
            state.cartItems[itemIndex].server.count += 1;
          state.cartItems[itemIndex].total_price =
            state.cartItems[itemIndex].cartQuantity *
            state.cartItems[itemIndex].price;
          // toast.info(
          //   `An additional ${state.cartItems[itemIndex].title} has been added to the cart`
          // );
        } else {
          const tempProduct = {
            ...action.payload,
            cartQuantity: 1,
            total_price: action.payload?.price,
          };

          toast.success(
            `${action.payload.title || 'The item'} was added to cart`
          );
          state.cartItems.push(tempProduct);
        }
      }
      state.isOpenModalCart = action.payload.openModal === false ? false : true;
      localStorage.setItem(
        cartKeys.CART_ITEMS,
        JSON.stringify(state.cartItems)
      );
    },
    removeFromCart(state, action) {
      const nextCartItems = state.cartItems.filter(
        (cartItem) => cartItem.id !== action.payload.id
      );
      state.cartItems = nextCartItems;
      localStorage.setItem(
        cartKeys.CART_ITEMS,
        JSON.stringify(state.cartItems)
      );
      if (!action.payload.isCleaning)
        toast.error(`${action.payload.title} was removed from the cart`);
    },
    decreaseCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (cartItem) =>
          cartItem.id === action.payload.product.id &&
          cartItem.locale === action.payload.locale
      );
      if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;
        state.cartItems[itemIndex].total_price =
          state.cartItems[itemIndex].cartQuantity *
          state.cartItems[itemIndex].price;
        // toast.info(`Quantity of ${action.payload.product.title} decreased`);
      } else if (state.cartItems[itemIndex].cartQuantity === 1) {
        const updatedItems = state.cartItems.filter(
          ({ id, locale }) =>
            id !== action.payload.product.id || locale !== action.payload.locale
        );
        state.cartItems = updatedItems;
        localStorage.setItem(
          cartKeys.CART_ITEMS,
          JSON.stringify(state.cartItems)
        );
        // toast.error(`${action.payload.product.title} removed from cart`);
      }
    },
    getTotals(state, action) {
      let { total, quantity } = state.cartItems
        .filter((item) => item.locale === action.payload)
        .reduce(
          (cartTotal, cartItem) => {
            const { price, cartQuantity } = cartItem;
            const itemTotal = price * cartQuantity;

            cartTotal.total += itemTotal;
            cartTotal.quantity += cartQuantity;
            return cartTotal;
          },
          {
            total: 0,
            quantity: 0,
          }
        );
      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = total;
    },
    clearCart(state, action) {
      // Extracting the 'locale' property from the 'action' payload
      const locale = action.payload;

      // Filtering out cart items based on the condition that the item's locale is not equal to the extracted 'locale'
      const filteredCartItems = state.cartItems.filter(
        (item) => item.locale !== locale
      );
      // Updating the state with the filtered cart items
      state.cartItems = filteredCartItems;
      // Saving the updated cart items to local storage after converting it to a JSON string
      localStorage.setItem(
        cartKeys.CART_ITEMS,
        JSON.stringify(state.cartItems)
      );
    },
    setModalCart(state, action) {
      state.isOpenModalCart = action.payload.open;
    },
    setCartOpen(state, action) {
      state.isCartOpen = action.payload;
    },
  },
});

export const {
  setCartItems,
  addToCart,
  removeFromCart,
  decreaseCart,
  getTotals,
  clearCart,
  setModalCart,
  setCartOpen,
} = cartSlice.actions;

export default cartSlice.reducer;
