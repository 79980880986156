import Link from 'next/link';
import classNames from '../../../functions/classNames';

const SecondaryButton = (props) => {
  const className = classNames(
    'flex justify-center items-center shadow-sm focus:outline-none border border-gray-900 transition-all text-gray-900',
    props.rounded ?? 'rounded-md',
    props.padding ?? 'px-4 py-2',
    props.fullWidth ? 'w-full' : 'w-fit',
    props.disabled
      ? 'bg-gray-900 text-white cursor-not-allowed'
      : 'bg-white hover:bg-gray-900 hover:text-white',
    props.className ?? ''
  );

  if (props.href) {
    return (
      <Link
        href={props.href}
        passHref
        prefetch={false}
      >
        <a
          className={className}
          onClick={props.onClick || undefined}
          target={props.target}
        >
          {props.children}
        </a>
      </Link>
    );
  }

  return (
    <button
      id={props.id ?? 'button'}
      type={props.type ?? 'button'}
      onClick={props.onClick}
      disabled={props.disabled}
      className={className}
      aria-label={props.ariaLabel}
    >
      {props.children}
    </button>
  );
};

export default SecondaryButton;

/* import classNames from "../../../functions/classNames";
import ConditionalLink from "../links/ConditionalLink";

const SecondaryButton = (props) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      className={classNames(
        "flex justify-center items-center shadow-sm text-sm rounded-md focus:outline-none text-indigo-700 bg-indigo-100 hover:bg-indigo-200",
        !props.href && "px-4 py-2",
        props.className
      )}
    >
      <ConditionalLink href={props.href} condition={props.href} className="flex flex-1 px-4 py-2">
        {props.children}
      </ConditionalLink>
    </button>
  );
};

export default SecondaryButton;
 */
